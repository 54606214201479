<template>
  <article>
    <Hero heading="Thanks! Now check your email to finish submitting this form." :subheading="true">
      <template #subheading>
        <p>We received your request, and we've sent a verification email to {{ userEmail }}.</p>
        <p>
          Click the link in the email within the next 48 hours to complete your registration and receive updates from
          us.
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'verify-email',
  metaInfo: {
    title: 'Verify your email | Electrify America',
    meta: [
      {
        name: 'description',
        content: 'Check your email to complete your registration and receive responses from us.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  components: { Hero },
  computed: {
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>
